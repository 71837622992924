import React from "react"

import Layout from "../components/layout.tr"
import SEO from "../components/seo"

export default () => {

  return (
    <Layout light={false}>
      <SEO title="404: Not found" />
      <div className={`content`}>
        <div className="page-not-found-container">
          <lottie-player
            className="not-found-anime"
            autoplay
            loop
            mode="normal"
            src={"https://assets6.lottiefiles.com/packages/lf20_nvywre8x.json"}
          ></lottie-player>
        </div>
      </div>

    </Layout>
  );
}